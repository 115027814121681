<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
          class="wizard wizard-2"
          id="kt_wizard_v2"
          data-wizard-state="step-first"
          data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <div class="row">
              <div class="col-md-12">
                <router-link :to="{name:'dashboard'}">
                  <i class="fas fa-home"></i> Dashboard
                </router-link> |
                Jobs
              </div>
            </div>
            <div class="row">
              <div class="col-6" v-if="checkIsAccessible('job-position', 'create')">
                <h4>
                  <router-link :to="{name:'job-position'}">
                    <i class="fas fa-arrow-left"></i>
                  </router-link> Job Position
                </h4>
              </div>
              <!--                        <div class="col-2 ">-->
              <!--                            <router-link :to="{name:'dashboard'}" class="btn btn-primary">-->
              <!--                                <i class="fas fa-arrow-left"></i>-->
              <!--                                Back-->
              <!--                            </router-link>-->
              <!--                        </div>-->
              <!--                        <div class="col-4">-->
              <!--                            <h4>Manage Slider Image</h4>-->
              <!--                        </div>-->
              <div class="col-6 text-right" v-if="checkIsAccessible('job-position', 'create')">
                <button class="btn btn-primary ml-2" @click="addJobPosition">

                  Add Job Position
                </button>
              </div>
              <div class="col-12">
                <table class="table table-stripe">
                  <thead>
                    <th>Title</th>
                    <th>Status</th>
                    <th>Action</th>
                  </thead>
                  <tbody>
                    <tr v-for="(position, index) of positions" :key="index">
                      <td>
                        <span v-if="position.title">{{position.title}}</span>
                        <span v-else>-</span>
                      </td>
                      <td>
                        <span  :class="position.is_active?'badge badge-success':'badge badge-danger'">{{position.is_active?'Active':'Inactive'}}</span>
                      </td>
                      <td>
                        <span class="cursor-pointer" @click="editJobPosition(position)" v-if="checkIsAccessible('job-position', 'edit')">
                          <i class="fas fa-edit"></i>
                        </span>
                        <span class="cursor-pointer ml-2" @click="deleteJobPosition(position.id)" v-if="checkIsAccessible('job-position', 'delete')">
                          <i class="fas fa-trash"></i>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                  <!-- <div class="row">
                    <div class="col-12">
                      <v-pagination
                        @input="getJobPostions"
                        v-model="page"
                        :total-visible="8"
                        :length="length"
                        circle
                      ></v-pagination>
                    </div>
                  </div>-->
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <create-and-update ref="job-position" @refresh-list="gettJobPostions"></create-and-update>
  </v-app>
</template>
<script>
import JobPositionService from "@/core/services/job-position/JobPositionService";
import CreateAndUpdate from "./CreateAndUpdate";

const positionService = new JobPositionService();

export default {
  name: "users",
  components: {
        CreateAndUpdate
  },
  data() {
    return {
      positions: [],
      page: null,
      total: null,
      perPage: null
    };
  },
  mounted() {
    this.gettJobPostions();
  },
  methods: {
    gettJobPostions() {
      positionService.paginate(this.page).then(response => {
        this.positions = response.data.data;
        this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
      });
    },
    addJobPosition(){
          this.$refs['job-position'].showModal()
    },editJobPosition(position){
          this.$refs['job-position'].showModal(position)
    },

    deleteJobPosition(id) {
      this.$confirm({
        message: `Are you sure you want to delete this item?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            positionService
              .delete(id)
              .then(response => {
                this.$snotify.success("Information deleted");

                this.gettJobPostions();
              })
              .catch(error => {
                //console.log(error);
              });
          }
        }
      });
    }
  }
};
</script>
