<template>
  <div>
    <b-modal id="modal-scrollable" size="lg" ref="my-modal" hide-footer title="Add Job Position">
      <div class="row">
        <div class="col-8 form-group">
          <input type="text" class="form-control" v-model="position.title" placeholder="Title" />
          <span class="text-danger" v-if="$v.position.title.$error">Title is is required.</span>
          <!-- <span class="text-danger" v-if="sizeExceed">File Size Exceed.</span> -->
        </div>
        <div class="col-4 form-group">
          <b-form-checkbox v-model="position.is_active" name="check-button" class switch>Status</b-form-checkbox>
          <!-- <span class="text-danger" v-if="sizeExceed">File Size Exceed.</span> -->
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <v-btn class="text-primary" depressed block @click="hideModal">Cancel</v-btn>
        </div>
        <div class="col-6">
          <v-btn v-if="checkIsAccessible('job-position', 'create')"
            class="text-primary btn btn-primary"
            depressed
            :disabled="sizeExceed"
            block
            @click="createOrUpdate"
            :loading="isBusy"
          >Save</v-btn>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import JobPositionService from "@/core/services/job-position/JobPositionService";
const positionService = new JobPositionService();
export default {
  props: ["album_id"],
  validations: {
    position: { title: { required } }
  },
  data() {
    return {
      edit: false,
      isBusy: false,
      position: {
        title: null,
        is_active: null
      }
    };
  },
  methods: {
    showModal(position = {}) {
      if (position.id) {
        this.position = position;
        this.position.is_active = this.position.is_active?true:false
        this.edit = true;
      }
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    toggleModal() {
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    getAlbum(id) {
      positionService.show(id).then(response => {
        this.album = response.data.album;
      });
    },

    createOrUpdate() {
      this.$v.position.$touch();
      if (this.$v.position.$error) {
        setTimeout(() => {
          this.$v.position.$reset();
        }, 3000);
      } else {
        if (this.edit) {
          this.updateJobPosition();
        } else {
          this.createJobPosition();
        }
      }
    },
    updateJobPosition() {
      this.isBusy = true;
      positionService
        .update(this.position.id, this.position)
        .then(response => {
          this.isBusy = false;

          this.$snotify.success("Information updated");
          this.resetForm();
        })
        .catch(error => {
          this.isBusy = false;
          this.$snotify.error("Something Went Wrong");
          // console.log(error);
        });
    },
    createJobPosition() {
      this.isBusy = true;
      positionService
        .store(this.position)
        .then(response => {
          this.isBusy = false;

          this.$snotify.success("Information added");
          this.resetForm();
        })
        .catch(error => {
          this.isBusy = false;
          this.$snotify.error("Something Went Wrong");
          // console.log(error);
        });
    },
    resetForm() {
      this.edit = false;
      this.position = {
        title: null,
        is_active: null
      };
      this.hideModal();
      this.$emit("refresh-list");
    }
  }
};
</script>
